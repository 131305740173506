@import "../../scss/base";
#getStarted {
  text-align: center;

  svg {
    color: $primary-purple;
  }

  h1#brand {
    font-weight: bold;
    font-size: 5vmax;
    padding: 0;
    color: rgba(0, 0, 0, 0.1);
    display: inline-block;
    transition: all ease-in-out 300ms;

    &:hover {
      cursor:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>❤️</text></svg>") 8 0,auto;
      opacity: 0.8;
      transform: scale(1.1);
      color: $primary-purple;
    }
  }
}
@import url('https://fonts.googleapis.com/css2?family=Antic+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maitree:wght@400;700&display=swap');
@import '~antd/dist/antd.css';

$primary-purple: rgb(90, 50, 255);
$primary-border-color: rgb(235, 235, 235);
$primary-gray: rgb(246, 248, 250);

$primary-green: rgb(0, 185, 120);
$primary-red: rgb(255, 110, 90);

$header-height: 70px;
$screen-breakpoint-large: 1100px;
$screen-breakpoint-small: 800px;

body {
  margin: 0 !important;
  padding: 0 !important;

  h1, h2, h3 {
    margin: 0;
  }

  h2 {
    font-family: 'Antic Slab', serif;
  }

  font-family: 'Maitree', serif;
}

.ant-tabs {
  overflow: visible !important;
}

.successButtonStyle {
  border: 1px solid white;
  background-color: $primary-green;
  color: white;
  transition: all ease-in-out 300ms;

  &:hover {
    background-color: white;
    border: 1px solid $primary-green;
    color: $primary-green;
  }
}

.dangerButtonStyle {
  border: 1px solid white;
  background-color: $primary-red;
  color: white;
  transition: all ease-in-out 300ms;

  &:hover {
    background-color: white;
    border: 1px solid $primary-red;
    color: $primary-red;
  }
}

.successButton {
  @extend .successButtonStyle;
}

.dangerButton {
  @extend .dangerButtonStyle;
}

.ant-btn-group {
  &.successButtonGroup {
    button:first-child {
      @extend .successButtonStyle;
      border-color: $primary-green;
    }
  }

  &.dangerButtonGroup {
    button:first-child {
      @extend .dangerButtonStyle;
      border-color: $primary-red;
    }
  }
}

.primaryContainer {
  padding: 10px;
  background-color: white;
  border: 1px solid rgb(235, 235, 235);
}

.dangerText {
  color: $primary-red;
}

.successText {
  color: $primary-green;
}

/* clock */

.clock-app-toggle {
  position: absolute;
  bottom: 24px;
}

.clock-hand {
  z-index: 200;
  bottom: 50px;
  position: absolute;
  border-radius: 2px;
  transform-origin: bottom;
}

.clock-hours {
  width: 4px;
  height: 45px;
  background: grey;
}

.clock-minutes {
  width: 4px;
  height: 70px;
  background: grey;
}
.clock-seconds {
  width: 2px;
  height: 90px;
  background: var(--error);
}

.clock-point {
  width: 8px;
  height: 8px;
  z-index: 300;
  border-radius: 4px;
  background: grey;
}

.marker--1,
.marker--3 {
  width: 4px;
  height: 16px;
}
.marker--2,
.marker--4 {
  height: 4px;
  width: 16px;
}

.marker--1,
.marker--3,
.marker--2,
.marker--4 {
  border-radius: 2px;
  box-shadow: var(--box-shadow-inset-small);
}

.marker--1 {
  top: 2px;
}

.marker--2 {
  left: 2px;
}

.marker--3 {
  bottom: 2px;
}

.marker--4 {
  right: 2px;
}

.clock-dashed {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='112' ry='112' stroke='grey' stroke-width='18' stroke-dasharray='1%2c4.5' stroke-dashoffset='25' stroke-linecap='butt'/%3e%3c/svg%3e") !important;
}

.clock-dashed--animating {
  animation: dash-animate 10s linear infinite;
}

@keyframes dash-animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@import "../../../scss/base";

.tileGroup {
  background-color: $primary-gray;
  border: 1px solid $primary-border-color;
  box-sizing: border-box;
  margin-bottom: 10px;

  h2 {
    text-align: center;
    padding: 10px;
  }

  .tileWrapper {
    margin: auto 0;

    .tile {
      margin: 0;
      display: inline-block;
    }
  }
}
@import "../../../../scss/base";

#tileEntriesList {
  box-sizing: border-box;
  .entryCol {
    display:flex;
    width: 100%;

    .tileEntry {
      overflow: hidden;
      width: 100%;
      padding: 10px;
      border: 1px solid $primary-border-color;
    }
  }
}
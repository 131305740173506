@import "../../scss/base";

#studentDashboard {
  padding: 10px;
  box-sizing: border-box;

  .column {
    display: inline-block;
    vertical-align: top;
    padding: 5px;
    box-sizing: border-box;

    &.small {
      width: 40%;

      .tile {
        width: calc(100% / 2);
      }
    }

    &.middle {
      width: calc(100% / 2);

      .tile {
        width: calc(100% / 3);
      }
    }

    &.large {
      width: 60%;

      .tile {
        width: calc(100% / 3);
      }
    }

    &.fullwidth {
      width: 100%;

      .tile {
        width: calc(100% / 5);
      }
    }

    @media screen and (max-width: $screen-breakpoint-large) {
      &.small {
        width: calc(100% / 3);
        .tile {
          width: 100% !important;
        }
      }

      &.medium {
        width: calc(100% / 2);
        .tile {
          width: calc(100% / 2) !important;
        }
      }

      &.large {
        width: calc(2 * 100% / 3);
        .tile {
          width: calc(100% / 2) !important;
        }
      }

      &.fullwidth {
        width: 100%;
        .tile {
          width: calc(100% / 3) !important;
        }
      }
    }

    @media screen and (max-width: $screen-breakpoint-small) {
      width: 100% !important;

      .tile {
        width: calc(100% / 2) !important;
      }
    }
  }
}

#discussionsList {
  .discussion {
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #d5d5d5;

    h2 {
      margin: 0;
      padding: 0;
    }
  }
}
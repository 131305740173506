@import "../../../../scss/base";

#binaryEntries {
  box-sizing: border-box;

  .entry {
    box-sizing: border-box;
    padding: 10px;
    overflow: hidden;

    &.fail {
      background-color: #eb4d70;

      h2, span {
        color: white;
      }
    }

    &.success {
      background-color: #6ce18b;
    }

    h2 {
      white-space: nowrap;
      text-overflow: ellipsis !important;
    }
  }
}
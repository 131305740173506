@import "../../scss/base.scss";

#adminHeader {
  padding: 0 20px;
  height: $header-height;
  background-color: $primary-purple;
  display: table;
  width: 100%;

  h1, h2 {
    color: white;
    vertical-align: middle;
    line-height: $header-height;
  }

  h1 {
    font-weight: 600;
  }

  h3 {
    margin-top: 15px;
    color: white;
    border: 1px solid white;
    padding: 5px;
    border-radius: 5px;
    transition: background-color ease-in-out 300ms;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  h1, #studentPicker {
    display: inline-block;
  }

  #studentPicker {
    margin: 0;
    width: 40vw;
    max-width: 400px;
    z-index: 20000;

    .css-1wa3eu0-placeholder, svg {
      color: white !important;
    }
  }

  #navbarContent {
    #brand {
      padding-right: 20px;
    }

    #brand, #inner {
      display: inline-block;
    }
  }
}

#debugNotice {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid $primary-border-color;
  background-color: $primary-red;
  position: sticky;
  top: 0;
  z-index: 1000;
}
@import "../../../../scss/base";

.tile {
  border: none !important;
  padding: 10px;
  width: 100%;

  .wrapper {
    padding-top: 300px;
    width: 100%;
    background-color: white;
    position: relative;
    //min-height: 250px;
    transition: border ease-in-out 300ms;
    border: 1px solid $primary-border-color;

    &:hover {
      z-index: 100;
      border: 1px solid $primary-purple;
      cursor: pointer;
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 10px;
      z-index: 10;
      background-color: white;
      border: none;

      h2 {
        font-weight: bold;
      }
    }
  }
}